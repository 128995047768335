#main-terms {
    max-width: 800px;
    background: #E5E5E5;
    margin: 0 auto;
    height: 100%;
}

#terms-container {
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 5%;
}

.terms-header {
    padding-top: 5%;
    font-size: 22px;
    font-family: bmwnextthin;
}

.terms-content {
    font-family: bmwnextli;
    font-size: 14px;
}