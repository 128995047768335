.adminpanel-nav {
    list-style-type: none;
    float: left;
    position: absolute;
    margin-top: -43px;
}

.admin-item {
    cursor: pointer;
    display: inline-block;
    font-size: 20px;
    padding: 40px;
    cursor: pointer;
    text-decoration: none;
    color: #9D9D9D;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    padding-top: 0px;
    padding-bottom: 20px;
    user-select: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.admin-item:hover {
    color: #262626;
    border-bottom: 4px solid #1b69d4;
}

.admin-item-selected {
    color: #262626;
    border-bottom: 4px solid #1b69d4;
}

.admin-item-selected:hover {
    color: #1b69d4 !important;
}

.admin-content {
    margin-top: 80px;
}