#overview-title {
    font-size: 20px;
    font-family: bmwnextbo;
}

#overview-dep {
    font-size: 18px;
    font-family: bmwnextli;
}

#data-table {
    max-height: 610px;
    overflow-y: auto;
}

@media only screen and (max-width: 1300px) {
    #data-table {
        max-height: 500px;
    }
}

.circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.red {
    background: red;
}

.yellow {
    background: yellow;
}

.green {
    background: green;
}

.blue {
    background: blue;
}