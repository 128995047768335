.slots {
    border: 1px solid black;
    height: 80px;
    width: 80px;
    margin: 0 auto;
    padding: 10px;
    position: relative;
    cursor: pointer;
    margin-top: 10px;
}

.slot-start {
    font-family: 'bmwnextbo';
    font-size: 16px;
    padding-bottom: 2px;
}

.slot-end {
    font-family: 'bmwnextli';
    font-size: 14px;
    padding-top: 2px;
}

.slot-middle-text {
    font-family: 'bmwnextthin';
    font-size: 9px;
}

.selected {
    color: white;
    background-color: #1C69D4;
    border: 2px solid #1C69D4;
}