.progress-header {
    font-family: 'bmwnextbo';
    font-size: 22px;
    height: 63px;
    padding-top: 30px;
    margin-bottom: 20px;
    text-align: center;
}

.progress-div {
    /* background-color: rgb(233, 233, 233); */
    border-radius: .5rem;
    width: 100%;
}

.progress-main {
    width: 100%;
}

.progress {
    position: relative;
    background: linear-gradient(to right, #0066B1, #0066B1 33.33%, #003D78 33.33%, #003D78 66.66%, #E22718 66.66%);
    height: 5px;
    border-radius: 1rem;
}

.progress-line {
    position: relative;
    top: -4px;
    border-top: 1px solid #E6E6E6;
}

.progress-bar {
    position: relative;
    top: 0px;
    right: 0px;
    float: right;
    transition: 1s ease;
    transition-delay: 0.5s;
    background-color: white;
    height: 8px;
    z-index: 1;
    margin-top: -1px;
}

#progress-icon-back {
    float: left;
    cursor: pointer;
    margin-top: 2px;
}

#progress-icon-forward {
    float: right;
    cursor: pointer;
    margin-top: 2px;
}

@media screen and (max-width: 500px) {
    #progress-icon-back {
        position: relative;
        left: -10px;
    }
    #progress-icon-forward {
        position: relative;
        right: -10px;
    }
}

@media screen and (min-width: 1024px) {
    #progress-icon-back {
        margin-top: 5px;
    }
    #progress-icon-forward {
        margin-top: 5px;
    }
    .progress-header {
        font-family: "bmwnextbo";
        font-size: 28px;
        padding-top: 10px;
        margin-bottom: 20px;
    }
}

