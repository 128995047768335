.navbar {
    transition: background-color 1s ease;
    overflow: hidden;
    position: absolute;
    top: 0px;
    height: 100px;
    right: 0px;
    left: 0px;
    padding-left: 130px;
    background-color: transparent;
    z-index: 2;
}

#go-icon {
    background-color: silver;
}

.navbar-open {
    transition: background-color 1.5s ease;
    background-color: white;
    box-shadow: 0px 0px 8px 4px #4F4F4F;
}

.navbar:before {
    content: "";
    position: absolute;
    left: 130px;
    right: 170px;
    bottom: 0;
    height: 1px;
    border-bottom: 1px solid #FFFFFF;
}

.mobile-close-head {
    z-index: 100;
}

.item {
    cursor: pointer;
    text-decoration: none;
    float: left;
    display: block;
    color: #FFFFFF;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    padding-top: 40px;
    padding-bottom: 34px;
    user-select: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.item-open {
    color: #9D9D9D;
}

.item-active {
    border-bottom: 5px solid #1b69d4;
    color: #262626 !important;
}

.item-open:hover {
    color: #262626;
}

.text {
    padding-left: 34px;
    padding-right: 34px;
}

.icon-header {
    padding-left: 15px;
    padding-right: 15px;
}

.item:hover {
    border-bottom: 5px solid #1b69d4;
}

.icongroup {
    display: flex;
    justify-content: flex-end;
    height: 99px;
    padding-right: 200px;
}

.logo {
    z-index: 8;
    position: absolute;
    top: 25px;
    right: 118px;
}

@media screen and (max-width: 1100px) {
    .logo {
        top: 23px;
        right: 40px;
    }
    .navbar {
        padding-left: 30px;
        /* height: 90px; */
    }
    /* .item {
        padding-top: 34px;
        font-size: 16px;
    } */
    .navbar:before {
        position: absolute;
        left: 30px;
        right: 90px;
        bottom: 0;
        height: 1px;
        border-bottom: 1px solid #E6E6E6;
    }
    .icongroup {
        position: absolute;
        right: 120px;
        padding-right: 0px;
    }
    .item-active {
        border-bottom: 5px solid #1b69d4 !important;
        color: #000000 !important;
    }
    .item:hover {
        color: white;
        border-bottom: 0;
    }
    .navbar-open {
        transition: background-color 0s ease;
        /* box-shadow: 0px 0px 0px 0px transparent; */
        display: flex;
    }
    .navbar-center {
        margin: auto auto;
        padding-right: 58px;
    }
}

@media screen and (max-width: 767px) {
    .bm-burger-button {
        position: fixed;
        width: 20px;
        height: 20px;
        left: 36px;
        top: 36px;
    }
    .bm-burger-bars {
        background: #FCF7E9;
    }
    .bm-cross {
        background: #303215;
        height: 26px !important;
        width: 5px !important;
    }

    .bm-cross-button {
        position: fixed !important;
        left: 36px !important;
        top: 36px !important;

    }
    .bm-menu {
        background: white;
        padding: 8em 1.5em 0;
        font-size: 1.15em;
    }
    .logo {
        top: 25px;
        right: 40px;
        z-index: 0;
    }
    .navbar {
        transition: background-color .5s ease;
        padding-left: 20px;
        padding-right: 20px;
        height: 100vh;
        width: 95vw;
    }
    .navbar:before {
        border-bottom: 0;
    }
    .text {
        padding-left: 0px;
        padding-right: 0px;
        font-size: 17px;
        color: #000000;
    }
    .text-sub {
        padding-left: 25px;
        padding-right: 0px;
        font-size: 20px;
    }
    .icon-header {
        float: left;
        margin-left: -20px;
        display: block;
        cursor: pointer;
    }
    .icon-right {
        float: right;
        display: block;
        cursor: pointer;
    }
    .item {
        text-align: left;
        padding-top: 17px;
        padding-bottom: 17px;
        margin-left: 20px;
        width: 70vw;
    }
    .item-open {
        color: black;
        border-bottom: 1px solid #E6E6E6;
    }
    .item-open:hover {
        border-bottom: 1px solid #E6E6E6;
    }
    .item-active {
        border-bottom: 1px solid #E6E6E6 !important;
        color: #000000 !important;
    }
    .item-mobile {
        position: absolute;
        left: 0;
        margin-top: 120px;
        margin-left: 30px;
        /* margin: 0;
        position: absolute;
        top: 27%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%); */
    }
    .mobile-footer {
        position: fixed;
        left: 0;
        bottom: 0;
        margin-left: 30px;
        /* display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row; */
    }
}