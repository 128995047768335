
.rotate-back {
    visibility: hidden;
    animation: rotate-keyframes-back .5s;
    transform: rotate(0deg);
}

.rotate {
    visibility: hidden;
    animation: rotate-keyframes .5s;
    transform: rotate(90deg);
}

@keyframes rotate-keyframes-back {
    from {
        transform: rotate(90deg);
    }
    to {
        transform: rotate(0deg);
    }
}

@keyframes rotate-keyframes {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(90deg);
    }
}

