.backdrop {
  width: 100%;
  height: 100%;
  /* height: 200vh; */
  position: absolute;
  left: 0px;
  bottom: 0px;
  z-index: 1199;
  background-color: black;
  opacity: 0.3;
}

.main {
  width: 100%;
  /* height: calc(100vh - 280px); */
  /* height: 74%; */
  /* height: 200px; */
  height: 470px;
  position: fixed;
  left: 0px;
  bottom: 0px;
  z-index: 1200;
  border-radius: 10px;
  background: white;
  overflow: hidden;
}

/*iPhone8*/

/* @media only screen and (min-device-width: 375px) and (max-device-width: 667px) {
  .main {
    height: 85%;
  }
} */

/*iPhone10*/

/* @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) {
  .main {
    height: 63vh;
  }
} */

.text-center {
  text-align: center!important;
}

.p-4 {
  padding-left: 1.5rem!important;
  padding-right: 1.5rem!important;
}

.p-3 {
  padding: 1rem!important;
}

.justify-content-center {
  justify-content: center!important;
}

.d-flex {
  display: flex!important;
}

.vh-100 {
  /* height: 100vh!important; */
}

.position-absolute {
  position: absolute!important;
}

.main-header {
  /* border-bottom: 1px solid#262626; */
  font-family: 'bmwnextbo';
  font-size: 22px;
  padding: 30px;
  position: relative;
  font-weight: bold;
  padding-bottom: 30px;
  line-height: 110%;
}

.mode-btn {
  width: 120px;
  height: 60px;
  border: 1px solid black;
  background: none;
  padding-top: 1rem;
  text-align: center;
}

.mode-btn input[type="radio"] {
  display: none;
}

.mode-btn:hover {
  background: #bbbbbb;
}

.mode-btn:active {
  background: #bbbbbb;
}

.more-driver {
  color: #1c69d4;
}

.option {
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #b8b5b5;
  background-color: white;
  margin: 0;
  cursor: pointer;
}

.option input {
  display: none;
}

.options-header {
  border-bottom: 1px solid #b8b5b5;
}

.options {
  padding: 1rem 4rem;
}

.input-fields {
  padding: 3rem;
  padding-left: 2rem;
  padding-right: 2rem;
  /* text-align: center; */
}

.month-view {
  /* width: 80%; */
  height: 400px;
  /* margin: auto; */
  overflow-y: auto;
}

.title {
  font-size: 17px;
  font-weight: bold;
}

.month {
  padding-left: 60px;
}

.continue-button {
  margin-top: 50px;
}

.buttons {
  margin-top: 125px;
}

.rotated-header {
  border-bottom: 1px solid #262626;
  justify-content: space-evenly;
  height: 5rem;
}

@media screen and (max-width: 666px) {
  .rotated-header p {
    text-align: center;
    font-size: 18px;
    width: 100%;
    margin: auto;
  }
}

@media (min-width: 667px) {
  .rotated-header p {
    font-size: 20px;
    width: 40%;
    margin: 10px auto;
  }
}

.rotated-header button {
  width: 230px;
  height: 3rem;
  color: white;
  border: 0;
  background: #0653b6;
  margin: auto;
}

.rotated-header button:active {
  background: #1c69d4;
}

.main-btn {
  background: #0653b6;
  width: 216px;
  height: 55px;
  color: white;
  font-size: 24px;
  line-height: 28px;
}

.main-btn:active {
  background: #1c69d4;
}

.start-button {
  margin-top: 65px;
}

.start-button:first-child {
  margin-top: 260px;
}

.continue-button {
  margin-top: 250px;
}

.back-calendar {
  width: 100%;
  height: 55px;
  cursor: pointer;
  background: none;
  padding-top: 15px;
  border-top: 1px solid #262626;
  border-bottom: 1px solid #262626;
  font-family: 'bmwnextbo';
  font-size: 16px;
}

.back-btn {
  width: 20px;
  height: 20px;
  font-size: 17px;
  transform: translate(-50%, 2px);
}

.back-label {
  line-height: 24px;
}

.appointment-info {
  margin: 1.5rem;
}

.appointment-info .info {
  line-height: 10px;
  padding-top: 1rem;
}

.appointment-info .info:first-child {
  padding-top: 0;
}

.appointment-btns {
  margin: 2rem;
}

.appointment-btns .apt-btn {
  width: 100%;
  height: 50px;
  padding-top: 13px;
  border-top: 1px solid #262626;
  cursor: pointer;
}

.appointment-btns .times {
  height: auto;
  /* padding: 1rem 3rem; */
  border-top: 1px solid #262626;
  border-bottom: 0;
}

.appointment-btns .times .date-time-picker {
  border-bottom: 1px solid #262626;
  width: 100%;
  height: 50px;
  padding-top: 13px;
}

.appointment-btns .times .date-time-picker input {
  border: none;
}

.appointment-btns .time {
  margin: 1rem;
  width: auto;
  border-top: none;
  border-bottom: 1px solid #262626;
  height: 40px;
}

.appointment-btns div:last-child {
  /* border-bottom: 1px solid #262626; */
}

.general-btn {
  width: 230px;
  height: 3rem;
  color: white;
  font-size: 19px;
  border: 0;
}

.confirm:active {
  background: #1c69d4;
}

.confirm {
  background: #0653b6;
}

.manual {
  background: #666666;
}

.manual:active {
  background: #bbbbbb;
}

.month-header {
  font-size: 19px;
  font-family: 'bmwnextbo';
}

.days {
  padding-top: 24px;
  padding-left: 8%;
  height: 270px;
  position: relative;
  cursor: pointer;
  font-size: 16px;
  font-family: 'bmwnextli';
}

.date {
  margin: 0 1rem 0rem 1rem;
  border-top: 1px solid#262626;
}

.time-slots {
  height: 50px;
  padding-top: 15px;
  cursor: pointer;
  /* border-top: 1px solid #e6e6e6; */
}

.slot {
  padding: 10px;
  /* GRID skal ændres 55px */
  font-family: 'bmwnextli';
  font-size: 18px;
  height: 57px;
  padding-top: 16px;
}

.selected-slot {
  background-color: #1c69d4;
  color: #ffffff;
  font-family: 'bmwnextbo'
}

.time-slots .slot:last-child {
  border-bottom: 1px solid #e6e6e6;
}

.time-slots {
  height: 55%;
  overflow-y: scroll;
}

.weeks {
  height: 45px;
  font-size: 10px;
  margin-left: -14px;
  font-size: 11px;
  margin-top: -14px;
  font-family: 'bmwnextli';
}

.week {
  width: 37px;
  height: 42px;
  padding-top: 17px;
}

.hrs .hr-line {
  height: 1px;
  border-top: 1px solid #e6e6e6;
}

.hrs {
  width: 85%;
  margin: auto;
}

.weeks-view {
  width: 80%;
  margin: auto;
}

.week-view {
  height: 50px;
  width: 300px;
  margin-left: -30px;
  padding-top: 6px;
}

.day {
  padding-top: 9px;
  padding-left: 2px;
  width: 37px;
  height: 37px;
  cursor: pointer;
  margin: 0;
}

.day-width {
  /* padding-left: 30px;
  padding-right: 30px; */
  margin-top: -2px;
  font-size: 18px;
}

.day-line {
  /* padding-left: 1px; */
}

.day input {
  display: none;
}

.day:hover {
  /* background: #1c69d4; */
  border-radius: 50%;
  color: white;
}

.week-no {
  font-size: 10px;
  font-family: 'bmwnextbo';
  left: -20px;
  top: -8px;
  position: absolute;
}

.week-no-view {
  width: 100%;
  overflow-x: auto;
}

.flow-input {
  height: 3rem;
  /* border-bottom: 1px solid#262626; */
  margin: 2rem;
}

.flow-input input {
  border: 0;
  width: 100%;
  height: 100%;
  text-align: center;
}

::placeholder {
  color: #bbbbbb;
  opacity: 1;
}

.personal-info {
  line-height: 16px;
}

.camera {
  z-index: 999999999;
  position: absolute;
  height: 100%;
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
}

.rdp-picker {
  margin: auto;
}

.rdp-column-container:nth-child(3) {
  display: none;
}

.close-duration {
  background: #ffffff;
  color: black;
  border: 1px solid black;
  margin: auto;
}

.appointments {
  height: calc(100% - 290px);
}

.appointment {
  /* height: 100%; */
  overflow-y: auto;
}

.appointment .slots {
  list-style: none;
}

.appointment .hr-line {
  border-top: 1px solid #e6e6e6;
}

@media screen and (min-width: 1024px) {
  .appointment .hr-line {
    font-size: 12px;
    font-family: 'bmwnextbo';
  }
}

.appointment .hr-line span {
  position: absolute;
  left: -2rem;
  transform: translate(-50%, -50%);
  font-size: 12px;
  font-family: 'bmwnextbo';
  padding-left: 10px;
}

.appointment .views {
  width: 70%;
  margin-left: 65px;
  list-style: none;
  position: relative;
  padding: 1rem 0 0 0;
}

.appointment .event-view {
  position: absolute;
  width: 100%;
  top: 0;
  color: white;
  cursor: pointer;
  border: 1px solid white;
}

@media screen and (min-width: 1024px) {
  .appointment .event-view {
    text-align: center;
  }
}

.appointment .event-view span {
  font-size: 14px;
  font-weight: bold;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  left: 50%;
  cursor: pointer;
}

.appointment-title {
  margin: 2rem 2rem 0 2rem;
  padding-bottom: 10px;
  border-bottom: 1px solid #BBBBBB;
  width: 80%;
}

.close-btn {
  position: absolute;
  left: 0;
  top: 7px;
  color: #1c69d4;
  font-size: 20px;
  cursor: pointer;
}

.rdp-reticule {
  border-top: 0;
}

#container-circles {
  top: 70%;
}

.btn-group {
  /* margin-top: 40px; */
  margin-left: 15px;
  margin-right: 15px;
}

.vertical {
  left: 0;
  bottom: 0;
  padding: 1em;
  transform: rotate(-90deg) translate(0, -100%);
  transform-origin: 100% 0;
  position: absolute;
}

.signature canvas {
  /* position: absolute; */
  left: 0;
}

.signature .clear-sign {
  position: absolute;
  top: 100%;
  right: 0;
  margin-right: 24px;
  margin-top: -55px;
  background: #666666;
  border: white;
  color: white;
  font-weight: bold;
}

.main-cal {
  margin-top: 20px;
  margin-left: 30px;
  margin-right: 30px;
}

.back-year {
  padding-top: 5px;
  padding-bottom: 8px;
  border-bottom: 1px solid black;
  text-align: center;
}

.slots-view {
  margin: 0 auto;
  margin-top: 10px;
  height: 270px;
  width: 260px;
}

.stepper {
  text-align: center;
  margin-top: 15px;
}

.icon-cpr {
  text-align: center;
  margin-top: 5px;
}

.last-info {
  margin-top: 20px;
  height: 340px;
}

.terms-cond {
  height: 200px;
  font-family: 'bmwnextli';
  font-size: 16px;
}

.overflow {
  overflow-y: auto;
  overflow-x: hidden;
}

.personal-business {
  margin-top: -5px;
  margin-bottom: 10px;
}

.personal-input {
  margin-top: 0px;
}

.done-view {
  height: 390px;
}

.appointment-view {
  margin-top: 25px;
  padding-top: 20px;
  height: 245px;
}

.view-app {
  height: 360px;
}

.error-text {
  font-size: 50px;
  color: red;
}

.header-title {
  text-align: center;
  padding: 20px;
  margin-top: 5px;
  font-size: 22px;
  font-family: 'bmwnextbo';
}

.underline {
  border-bottom: 1px solid black;
  margin-bottom: 9px;
}

.subject-textarea {
  font-family: 'bmwnextbo';
  font-size: 16px;
  border: none;
  overflow: none;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none;
  /*remove the resize handle on the bottom right*/
}

.field {
  border-radius: 5px;
}

.field-icon {
  padding: 18px;
}

.field-children {
  font-family: 'bmwnextli';
  font-size: 16px;
}

.calendar-btn-group {
  text-align: center;
}

.calendar-btn {
  margin-top: 15px;
}

.top-btn {
  margin-top: 25px;
}

.calendar-datepicker {
  border: none;
  overflow: none;
  outline: none;
  font-family: 'bmwnextli';
  font-size: 16px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  width: 185px;
  background-color: transparent;
}

.calendar-datepicker::-webkit-inner-spin-button, .calendar-datepicker::-webkit-calendar-picker-indicator {
  opacity: 0;
  -webkit-appearance: none;
}

.calendar-datepicker {
  -webkit-appearance: none;
}

.calendar-datepicker::-webkit-inner-spin-button, .calendar-datepicker::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.mobile-calendar-table {
  text-align: center;
  margin: auto;
  /* margin-top: -75px; */
}

.mobile-days-in-month {
  margin-bottom: -10px;
}

.mobile-calendar {
  text-align: center;
  margin-left: -15px;
  height: 320px;
  overflow-y: auto;
  width: 375px;
}

.mobile-days {
  padding: .69rem;
  font-size: 16px;
  font-family: 'bmwnextli';
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.mobile-weekdays {
  padding: .6rem;
  font-size: 14px;
  margin-top: -10px;
  font-family: 'bmwnextli';
}

.mobile-weekdays-short {
  margin-top: -8px;
  margin-left: 15px;
}

.mobile-month-header {
  font-size: 18px;
  font-family: 'bmwnextbo';
  padding: 15px 0px 10px 0px;
}

.mobile-weekno-line {
  text-align: left;
  border-bottom: .5px solid #8E8E8E;
  margin-top: -15px;
  width: 100%;
  float: right;
  /* margin-left: 35px;
  margin-right: 15px; */
}

.mobile-weeno {
  position: relative;
  top: 7px;
  left: -25px;
  font-family: 'bmwnextbo';
  font-size: 11px;
}

.mobile-today {
  background-color: #1C69D4;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  margin: auto;
  font-family: 'bmwnextbo';
  font-size: 16px;
  display: block;
  margin: auto;
  margin-top: 10px;
  /* line-height:50px; */
  /* text-align:center; */
  color: white;
}

.mobile-today-text {
  margin-top: -9px;
  margin-left: -2px;
}

.mobile-selected {
  font-size: 16px;
  font-family: 'bmwnextbo';
}

.testdrive-main-view {
  height: 400px;
  padding-bottom: 50px;
  overflow-y: auto;
  overflow-x: hidden;
}

@media screen and (min-width: 600px) {
  .testdrive-main-view {
    height: 100%;
  }
}

@media screen and (min-width: 1600px) {
  .personal-business {
    /* margin-left: 110px; */
  }
  .personal-business-external {
    margin-left: -40px;
  }
}

.plate-slot {
  /* padding-top: 15px;
  width: 110px;
  height: 55px;
  margin: 0 auto;
  margin-top: 9px; */
  padding-top: 16px;
  width: 110px;
  height: 55px;
  border: 1px solid black;
  font-size: 16px;
  font-family: 'bmwnextli';
}

.plate-container {
  margin-left: 45px;
  margin-right: 45px;
  overflow-y: auto;
}

.last-info-header {
  text-align: left;
  padding-bottom: 10px;
  font-size: 16px;
  font-family: 'bmwnextli';
}

/*iPhone8*/

@media only screen and (min-device-width: 375px) and (max-device-width: 667px) {
  .plate-container {
    height: 180px;
  }
  .testnote {
    height: 100px;
  }
}

/*iPhone10*/

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) {
  .plate-container {
    height: 185px;
  }
}

.main-spinner {
  text-align: center;
  padding-top: 100px;
}