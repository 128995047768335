.react-html5-camera-photo {
    position: absolute;
    text-align: center;
}

.react-html5-camera-photo>video {
    width: 768px;
}

.react-html5-camera-photo>img {
    width: 768px;
}

.react-html5-camera-photo>.display-error {
    width: 768px;
    margin: 0 auto;
}

@media(max-width:768px) {
    .react-html5-camera-photo>video, .react-html5-camera-photo>img {
        width: 100%;
    }
    .react-html5-camera-photo>.display-error {
        width: 100%;
    }
}

/* fullscreen enable by props */

.react-html5-camera-photo-fullscreen>video, .react-html5-camera-photo-fullscreen>img {
    width: 100vw;
    height: 100vh;
}

.react-html5-camera-photo-fullscreen>video {
    object-fit: fill;
}

.react-html5-camera-photo-fullscreen>.display-error {
    width: 100vw;
    height: 100vh;
}

.overlay {
    position: absolute;
    top: 20px;
    right: 20px;
    height: 70%;
    left: 20px;
    box-shadow: 0px 0px 20px 56px rgba(0, 0, 0, 0.6);
    border: 1px solid #ffffff;
    border-radius: 10px;
}

.closeBtn {
    position: absolute;
    top: 20px;
    right: 20px;
}

@media(min-width:768px) {
    .overlay {
        top: 20px;
        height: 88%;
        bottom: 20px;
        width: 80%;
    }
}