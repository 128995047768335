#desktop-main {
    padding-left: 70px;
    padding-right: 70px;
}

#desktop-cal {
    margin-top: 50px;
}

.desktop-week-day {
    height: 40px;
}

.desktop-head-cal {
    font-family: 'bmwnextbo';
    font-size: 28px;
    margin-bottom: 30px;
}


.desktop-search-main-button-group {
    text-align: center;
}

.desktop-search-main {
    margin-top: 20px;
    text-align: center;
}

.btn-desktop-flow {
    margin-top: 20px;
    
}

.dekstop-res-main {
    margin-top: 30px;
    text-align: center;
}

.input-last {
    margin-top: 30px;
}

.desktop-last-details-main {
    margin-top: 50px;
    padding-left: 20px;
    padding-right: 20px;
}

.desktop-last-details-subs {
    text-align: center;
}

.input-desktop-flow {
    margin-bottom: 30px;
}

#desktop-stepper {
    text-align: center;
    border-bottom: 1px solid #E6E6E6;
}

.btn-bottom {
    padding-bottom: 20px;
}

.desktop-done-btn {
    text-align: center;
    margin-top: 60px;
}

.desktop-header {
    /* padding-top: px; */
    text-align: center;
    font-size: 28px;
    font-family: 'bmwnextbo';

}

.desktop-week-day {
    text-align: center;
    width: 70px;
    font-size: 18px;
    font-family: 'bmwnextreg';
}

.desktop-calendar-day {
    text-align: center;
    height: 50px;
    cursor: pointer;
    font-size: 18px;
    font-family: 'bmwnextli';
}

.desktop-calendar-day:hover {
    font-family: 'bmwnextbo';
}

.desktop-calendar-highlight {
    font-family: 'bmwnextbo';
}

.desktop-cal-today {
    background-color: #3567CD;
    color: white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin: auto;
    font-family: 'bmwnextbo';
    font-size: 20px;
}

.desktop-cal-today:before {
    content: "";
    display: block;
    padding-top: 7px;
}

.desktop-month-picker {
    padding-left: 110px;
    text-align: left;
}

.desktop-month-picker-external {
    padding-left: 110px;
    text-align: left;
}

.desktop-cal-controls {
    height: 25px;
    width: 25px;
    cursor: pointer;
}

.desktop-month-title {
    font-family: 'bmwnextbo';
    font-size: 16px;
    margin: 10px 10px;
    left: 0px;
    bottom: 6px;
    position: relative;
}

.desktop-cal-table {
    background-color: #EDEDED;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 20px;
    margin-left: 95px;
    height: 350px;
    width: 500px;
}

.desktop-cal-table-external {
    background-color: #EDEDED;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 20px;
    margin-left: 95px;
    height: 350px;
    width: 500px;
}

.desktop-slot {
    height: 50px;
    padding-top: 14px;
    font-size: 15px;
    /* text-align: center;
    border: 1px solid black; */
}

.desktop-slot-header {
    font-family: 'bmwnextbo';
    font-size: 18px;
    margin-left: 49px;
    padding-top: 40px;
    padding-bottom: 12px;
    margin-right: 40px;
    margin-bottom: 12px;
    width: 435px;
    border-bottom: 1px solid black;
    text-align: left;
}

.desk-app-header {
    margin-top: 40px;
}

.desktop-slot:hover {
    color: white;
    font-family: 'bmwnextbo';
    cursor: pointer;
    background-color: #3567CD;
}

#desktop-slots {
    position: relative;
    top: 65px;
}

.divider {
    border: .05rem solid black;
}

.desktop-slots {
    height: 80px;
    width: 80px;
    border: 1px solid black;
    cursor: pointer;
}

.desktop-appointments {
    width: 460px;
    margin-left: 35px;
    height: 300px;
    overflow-y: auto;
}

.appointment-dialog {
    width: 375px;
    height: 500px;
}

.right-align-head {
    float: right;
    margin-top: -20px;
}

.left-align-head {
    float: left;
    margin-top: -20px;
}

.add-new-header {
    text-align: center;
    font-family: 'bmwnextbo';
    font-size: 22px;
    padding: 12px;
    margin-top: 12px;
}

@media screen and (min-width: 1600px) {
    .desktop-month-picker {
        padding-left: 210px;
    }

    .desktop-cal-table {
        margin-left: 195px;
    }
}

.desktop-slot-start {
    font-family: 'bmwnextbo';
    font-size: 16px;
    padding-top: 10px;
}

.desktop-slot-end {
    font-family: 'bmwnextli';
    font-size: 14px;
}

.desktop-slot-middle-text {
    font-family: 'bmwnextthin';
    font-size: 9px;
}

.desktop-selected {
    color: white;
    background-color: #3567CD;
    border-color: #3567CD;
}

.desktop-slots:hover {
    color: white;
    background-color: #3567CD;
    border-color: #3567CD;
}

@media screen and (max-width: 1200px) {

    .desktop-month-picker {
        padding-left: 30px;
    }

    .desktop-month-picker-external {
        padding-left: 30px;
    }

    .desktop-appointments {
        width: 350px;
    }

    .desktop-cal-table {
        margin-left: 30px;
        background-color: #EDEDED;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 10px;
        padding-bottom: 10px;
        height: 292px;
        width: 391px;
    }

    .desktop-cal-table-external {
        margin-left: 30px;
        background-color: #EDEDED;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 10px;
        padding-bottom: 10px;
        height: 292px;
        width: 391px;
    }

    .desktop-calendar-day {
        font-size: 16px;
        height: 40px;
    }
    

    .desktop-week-day {
        font-size: 16px;
        width: 40px;
    }

    #desktop-main {
        padding-left: 15px;
        padding-right: 15px;
    }

    #desktop-slots {
        position: relative;
        top: 25px;
    }

    .desktop-cal-today {
        width: 30px;
        height: 30px;
        font-size: 16px;
    }

    .desktop-cal-today::before {
        padding-top: 4px;
    }

    .desktop-slot-header {
        font-size: 16px;
        margin-top: 30px;
        margin-left: 49px;
        padding-top: 25px;
        padding-bottom: 12px;
        margin-right: 40px;
        margin-bottom: 12px;
        width: 300px;
        border-bottom: 1px solid black;
        text-align: left;
    }

    .desktop-flow-header {
        width: 260px;
    }

    #desktop-cal {
        margin-top: 25px;
    }

    #desktop-slots {
        top: 0px;
        left: 40px
    }

}