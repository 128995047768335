@font-face {
    font-family: "bmwnextreg";
    src:
        url("../assets/fonts/BMWTypeNextTT-Regular.woff") format("woff"),
        url("../assets/fonts/BMWTypeNextTT-Regular.ttf") format("ttf"),
        url("../assets/fonts/BMWTypeNext-Regular.woff2") format("woff2");
}

@font-face {
    font-family: "bmwnextbo";
    src:
        url("../assets/fonts/BMWTypeNextTT-Bold.woff") format("woff"),
        url("../assets/fonts/BMWTypeNextTT-Bold.ttf") format("ttf"),
        url("../assets/fonts/BMWTypeNext-Bold.woff2") format("woff2");
}

@font-face {
    font-family: "bmwnextli";
    src:
        url("../assets/fonts/BMWTypeNextTT-Light.woff") format("woff"),
        url("../assets/fonts/BMWTypeNextTT-Light.ttf") format("ttf"),
        url("../assets/fonts/BMWTypeNext-Light.woff2") format("woff2");
}

@font-face {
    font-family: "bmwnextthin";
    src:
        url("../assets/fonts/BMWTypeNextTT-Thin.woff") format("woff"),
        url("../assets/fonts/BMWTypeNextTT-Thin.ttf") format("ttf"),
        url("../assets/fonts/BMWTypeNext-Thin.woff2") format("woff2");
}

@font-face {
    font-family: "minibo";
    src:
        url("../assets/fonts/MINISerif-Bold.ttf") format("ttf");
}

@font-face {
    font-family: "minireg";
    src:
        url("../assets/fonts/MINISerif-Regular.ttf") format("ttf");
}

.body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
}

@media screen and (min-width: 500px) and (max-width: 1200px) and (orientation: portrait) {
    html {
      transform: rotate(-90deg);
      transform-origin: left top;
      width: 100vh;
      overflow-x: hidden;
      position: absolute;
      top: 100%;
      left: 0;
    }
  }

.Mui-selected {
    background-color: #1c69d4 !important;
    color: white !important;
    font-weight: bolder !important;
    font-family: 'bmwnextbo' !important;
}

.Mui-selected .MuiToggleButton-label {
    font-family: 'bmwnextbo' !important;
    font-weight: bolder !important;
}

.MuiCircularProgress-colorSecondary {
    color: #1c69d4 !important;
  }

.CustomToggleButton-root-362 {
    border-color: black !important;
}

.MuiToggleButton-root {
    border-color: black !important;
}

.CustomToggleButton-selected-1018 {
    border-color: #1c69d4 !important;
}

.dropdown-slidedown {
    height: 0px;
}
