.window {
    padding: 32px;
    transition: background-color 1s ease;
    z-index: 1;
    position: relative;
    top: 95px;
    background-color: white;
    margin-right: 64px;
    margin-left: 64px;
    /* max-height: calc(100vh - (99px + 64px)); */
    height: 650px;
    -webkit-box-shadow: 0px 3px 48px -11px rgba(79,79,79,1);
    -moz-box-shadow: 0px 3px 48px -11px rgba(79,79,79,1);
    box-shadow: 0px 3px 48px -11px rgba(79,79,79,1);
    overflow-y: auto;
    overflow-x: hidden;
}

.no-overflow {
    overflow-y: hidden;
}

@media screen and (min-width: 1600px) {
    .window {
        margin-right: 128px;
        margin-left: 128px;
        /* height: 674px; */
    }
}

.window-cal {
    z-index: 10;
    margin-right: 0px;
    margin-left: 0px;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    min-height: 100vh;
}

.window-small {
    height: 515px;
    width: 420px;
    margin: 0 auto;
    /* margin-right: 500px;
    margin-left: 500px; */
}

.window-medium {
    margin-right: 200px;
    margin-left: 200px;
}

.window-hidden {
    visibility: hidden;
}

@media screen and (max-width: 1360px) {
    .window-small {
        margin-right: 430px;
        margin-left: 430px;
    }
}

@media screen and (max-width: 1250px) {
    .window-small {
        margin-right: 360px;
        margin-left: 360px;
    }
}

@media screen and (max-width: 1150px) {
    .window-small {
        margin-right: 300px;
        margin-left: 300px;
    }
}

@media screen and (max-width: 1200px) {
    .window {
        transition: background-color 1s ease;
        position: absolute;
        right: 0px;
        left: 0px;
        top: 95px;
        height: 78%;
        bottom: 0px;
        overflow: hidden;
        padding-left: 0px;
        padding-right: 0px;
    }

    .window-cal {
        z-index: 10;
        top: 0px;
    }
}

@media screen and (max-width: 767px) {
    .window {
        transition: none;
        top: 0px;
        height: 100vh;
        z-index: 2;
        transition: 0;
        overflow-y: hidden;
        margin-right: 0px;
        margin-left: 0px;
        padding-left: 32px;
        padding-right: 32px;
        z-index: 1100;
        height: 100%;
    }

    .window-small {
        width: 100vw;
    }
}