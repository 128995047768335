#main {
    transform-origin: center center;
    position: absolute;
    top: 53%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    overflow-y: hidden;
    overflow-x: hidden;
    background-color: white;
}

#tc {
    padding: 5px;
}

#back-link {
    position: absolute;
    color: white;
    font-size: 18px;
    font-family: bmwnextbo;
    cursor: pointer;
}

#logo-icon-external {
    height: 50px;
    width: 50px;
}

#logo-external {
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

@media screen and (min-width: 1201px) {
    #main {
        width: 1280px;
        height: 620px;
    }

    #back-link {
        left: calc(50% - 640px);
        top: calc(50% - 350px);
    }

    #logo-external {
        right: calc(50% - 640px);
        top: calc(50% - 335px);
    }

    @keyframes mainAnimation {
        0.0% {
            width: 1px;
            height: 1px;
        }
        25.0% {
            width: 1px;
            height: 1px;
        }
        60% {
            width: 1280px;
            height: 1px;
        }
        62% {
            background-color: white;
        }
        100% {
            width: 1280px;
            height: 620px;
        }
    }
}

@media screen and (max-width: 1200px) {
    #main {
        width: 960px;
        height: 77vh;
        top: 52vh;
    }

    #back-link {
        top: 5%;
        left: 30px;
    }
    #logo-external {
        top: 7%;
        right: 20px;
    }
    @keyframes mainAnimation {
        0.0% {
            width: 1px;
            height: 1px;
        }
        25.0% {
            width: 1px;
            height: 1px;
        }
        60% {
            width: 960px;
            height: 1px;
        }
        62% {
            background-color: white;
        }
        100% {
            width: 960px;
            height: 77vh;
        }
    }
}

@media only screen and (max-width: 600px) {
    #main {
        top: calc(80vh / 2 + 75px);
        /* top: 68%; */
        left: 50%;
        width: 100vw;
        height: 80vh;
        overflow-x: hidden;
        overflow: hidden;
        padding-left: 10px;
        padding-right: 10px;
    }

    #back-link {
        top: 25px;
        left: 20px;
        font-size: 16px;
    }
    #logo-external {
        top: 40px;
        right: 10px;
    }

    #logo-icon-external {
        height: 35px;
        width: 35px;
    }

    @keyframes mainAnimation {
        0.0% {
            width: 1px;
            height: 1px;
            padding-left: 0px;
            padding-right: 0px;
        }
        25.0% {
            width: 1px;
            height: 1px;
        }
        60% {
            width: 100vw;
            height: 1px;
        }
        62% {
            padding-left: 0px;
            padding-right: 0px;
            background-color: white;
        }
        100% {
            width: 100vw;
            height: 80vh;
            padding-left: 10px;
            padding-right: 10px;
        }
    }
}

/* @media screen and (max-width: 600px) {
    #main {
        transform-origin: center center;
        position: absolute;
        top: 62%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        width: 100vw;
        height: 80vh;
    }
    #back-link {
        top: calc(50vh - (290px + 80px));
        left: calc(50vw - 500px);
    }
    #logo-external {
        top: calc(50vh - (290px + 70px));
        right: calc(50vw - 640px);
    }
    @keyframes mainAnimation {
        0.0% {
            width: 1px;
            height: 1px;
        }
        15.0% {
            width: 1px;
            height: 1px;
        }
        45% {
            width: 100vw;
            height: 1px;
        }
        52% {
            background-color: white;
        }
        100% {
            width: 100vw;
            height: 80vh;
        }
    }
} */