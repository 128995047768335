.slider {
    padding-bottom: 75px;
}

.carousel {
    font-family: bmwnextthin;
    display: flex;
    position: absolute;
    transition: all ease-out .3s;
    margin-top: -2rem;
}

.carousel-header {
    font-family: bmwnextthin;
    font-size: 1.4rem;
    padding-bottom: 1rem;
    margin-top: -10px;
}

.carousel-item {
    cursor: pointer;
    color: #AFABAB;
    margin-top: 3.40rem;
    font-size: 2rem;
    width: 65px;
    user-select: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.carousel-selected {
    cursor: pointer;
    transition: all ease .5s;
    font-size: 5rem;
    padding: 0px;
    width: 65px;
    user-select: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}


@media (max-width: 1200px) {
    .carousel-header {
        font-size: 1.1rem;
        margin-top: -10px;
    }

    .slider {
        padding-bottom: 62px;
        margin-top: -10px;
    }

    .carousel-item {
        padding: 0rem;
        margin-top: 2.6rem;
        font-size: 1.75rem;
        width: 55px;
    }

    .carousel-selected {
        font-size: 4.2rem;
        padding: 0rem;
        width: 55px;
    }

    .carousel {
        margin-top: -1.8rem;
    }

}

@media (max-width: 767px) {
    .carousel-header {
        font-size: 1.1rem;
    }

    .slider {
        padding-bottom: 50px;
        margin-top: -10px;
        
    }

    .carousel-item {
        padding: 0rem;
        margin-top: 2.3rem;
        font-size: 1.75rem;
        width: 55px;
    }

    .carousel-selected {
        font-size: 3.8rem;
        padding: 0rem;
        width: 55px;
    }

    .carousel {
        margin-top: -1.8rem;
    }

}
