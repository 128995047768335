.main-button {
    margin-top: 28px;
    margin-bottom: 12px;
    margin-left: -20px;
    cursor: pointer;
    text-align: center;
}

.back-button {
    font-family: 'bmwnextbo';
    font-size: 22px;
}

.back-button-icon {
    margin-bottom: -6px;
    margin-right: 5px;
}

.progress-static {
    /* width: 327px;
    background: linear-gradient(to right, #0066B1, #0066B1 33.33%, #003D78 33.33%, #003D78 66.66%, #E22718 66.66%); */
    margin-left: 1.5rem;
    margin-top: 1.3rem;
    height: 3px;
}